/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react';

import { Box, Button, Flex, Text, Image } from '@opendoor/bricks/core';
import { colors } from '@opendoor/bricks/theme/eero';

import * as api from '../api';
import imgixUrl from '../../helpers/imgixUrl';
import { COSMOS_URL, CONSUMER_FE_URL } from '../globals';
import { ExperienceType, ReturnExperienceData } from '../../helpers/returnBanner';

type HeadlineTextProps = {
  firstLine: string;
  secondLine: JSX.Element | string;
  address?: string;
};

const HeadlineText = (props: HeadlineTextProps) => (
  <Box color="neutrals100" margin="auto" pl={[null, null, null, 6]}>
    <Box display={[null, null, null, 'none']} pt={[3, null, null, 'initial']}>
      <Text fontWeight="bolder" lineHeight={['120', null, '140']}>
        {props.firstLine}
      </Text>
      <Text fontWeight="bolder" lineHeight={['120', null, '140']}>
        {props.secondLine}
      </Text>
    </Box>
    <Text
      display={['none', null, null, 'initial']}
      fontWeight="bolder"
      lineHeight={['120', null, '140']}
      pt={[3, null, null, 'initial']}
    >
      {props.firstLine + ' '}
      {props.secondLine}
    </Text>
    {props?.address && <Text>{props.address}</Text>}
  </Box>
);

type CTAButtonProps = {
  experience: ExperienceType;
  loading?: boolean;
  onClick?: () => void;
  url?: string;
  actionText: string;
};

const CTAButton = (props: CTAButtonProps): JSX.Element => {
  const onClick = () => {
    props.onClick && props.onClick();
    if (props.url) {
      window.location.href = props.url;
    }
  };

  return (
    <Button
      analyticsName={`cosmos-return-banner-${ExperienceType[props.experience]}`}
      trackImpression
      aria-label="return-banner-cta"
      loading={props.loading}
      onClick={onClick}
    >
      {props.actionText}
    </Button>
  );
};

type HeadlineComponentProps = {
  experience: ExperienceType;
  firstName?: string;
  address?: string;
  daysToExpire?: number;
};

const HeadlineComponent = (props: HeadlineComponentProps): JSX.Element | null => {
  switch (props.experience) {
    case ExperienceType.multiple_properties:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          fontSize="s0"
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
          borderBottom={[`1px solid ${colors.neutrals40}`, null, null, 'initial']}
        >
          <Image
            src={imgixUrl('5970e34ec121477b9089e6e18e202ff5.png')}
            alt=""
            alignSelf="center"
            width="40px"
            mx="auto"
          />
          <HeadlineText
            firstLine={`Welcome back, ${props.firstName}!`}
            secondLine="Still thinking of selling?"
          />
        </Flex>
      );
    case ExperienceType.offer_expired:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          fontSize="s0"
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
        >
          <Image
            src={imgixUrl('56cb13553eca44b588b821683c98241d.png')}
            alt=""
            alignSelf="center"
            width="40px"
            mx="auto"
          />
          <HeadlineText
            firstLine={`Welcome back, ${props.firstName}!`}
            secondLine={'Your offer is expired. Refresh for an updated offer.'}
            address={props.address}
          />
        </Flex>
      );
    case ExperienceType.incomplete_flow:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          fontSize="s0"
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
        >
          <Image
            src={imgixUrl('56cb13553eca44b588b821683c98241d.png')}
            alt=""
            alignSelf="center"
            width="40px"
            mx="auto"
          />
          <HeadlineText
            firstLine={'Welcome back!'}
            secondLine={'Take a minute to complete your offer on'}
            address={props.address}
          />
        </Flex>
      );
    case ExperienceType.offer_expiring:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          fontSize="s0"
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
        >
          <Image
            src={imgixUrl('56cb13553eca44b588b821683c98241d.png')}
            alt=""
            alignSelf="center"
            width="40px"
            mx="auto"
          />
          <HeadlineText
            firstLine={`Welcome back, ${props.firstName}!`}
            secondLine={
              <>
                Your preliminary offer expires{' '}
                <Box as="span" color="terracotta50">
                  {props.daysToExpire && props.daysToExpire > 1
                    ? `in ${props.daysToExpire} days.`
                    : 'today.'}
                </Box>
              </>
            }
            address={props.address}
          />
        </Flex>
      );
    case ExperienceType.offer_decreased:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          fontSize="s0"
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
          borderBottom={[`1px solid ${colors.neutrals40}`, null, null, 'initial']}
        >
          <Image
            src={imgixUrl('a432fc582ec04a6c83a63eced151657e.png')}
            alt=""
            alignSelf="center"
            width="40px"
            mx="auto"
          />
          <HeadlineText
            firstLine={`Welcome back, ${props.firstName}!`}
            secondLine="Your preliminary offer was updated."
            address={props.address}
          />
        </Flex>
      );
    case ExperienceType.offer_increased:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          fontSize="s0"
          px={[null, null, null, 6]}
          pt={[5, null, null, 7]}
          pb={[3, null, null, 7]}
        >
          <Image
            src={imgixUrl('8866f7d5729448cc991d1c2149ed3d28.png')}
            alt=""
            alignSelf="center"
            width="40px"
            mx="auto"
          />
          <HeadlineText
            firstLine={`Great news, ${props.firstName}!`}
            secondLine={
              <>
                Your preliminary offer has{' '}
                <Box as="span" color="green50">
                  increased.
                </Box>
              </>
            }
            address={props.address}
          />
        </Flex>
      );
    case ExperienceType.active_offer:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          fontSize="s0"
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
          borderBottom={[`1px solid ${colors.neutrals40}`, null, null, 'initial']}
        >
          <Image
            src={imgixUrl('5970e34ec121477b9089e6e18e202ff5.png')}
            alt=""
            alignSelf="center"
            width="40px"
            mx="auto"
          />
          <HeadlineText
            firstLine={`Welcome back, ${props.firstName}!`}
            secondLine="Still thinking of selling?"
            address={props.address}
          />
        </Flex>
      );
    default:
      return null;
  }
};

const BenefitLineItem = (props: { text: string }) => (
  <Flex>
    <Image src={imgixUrl('5517b44c75864df491a087b692ae8f7b.svg')} alt="Checkmark icon" />
    <Text pl={2}>{props.text}</Text>
  </Flex>
);

type OfferDetailsProps = {
  experience: ExperienceType;
  sellerInputUUID: string;
  currentOfferPriceCents?: number;
  previousOfferPriceCents?: number;
  offerRequestSource?: string;
  customerUUID?: string;
  addressToken?: string;
};

const OfferDetails = (props: OfferDetailsProps): JSX.Element | null => {
  let offerDashUrl;
  if (props.addressToken) {
    // Return to sell tab in hub for returning customers
    offerDashUrl = `${CONSUMER_FE_URL}/dashboard/sell/${props.addressToken}`;
  } else {
    offerDashUrl = `${CONSUMER_FE_URL}/dashboard/${props.sellerInputUUID}/overview`;
  }
  const [loading, setLoading] = useState(false);

  const fnHandleRefresh = (url: string, sellerInputUUID: string) => {
    return async () => {
      setLoading(true);
      await api.refreshOffer(sellerInputUUID);
      window.location.href = url;
    };
  };

  switch (props.experience) {
    case ExperienceType.multiple_properties:
      // eslint-disable-next-line no-case-declarations
      const propertiesUrl = `${COSMOS_URL}/properties`;
      return (
        <Box
          minWidth={[null, null, null, '25%']}
          width={[0.8, null, null, 'initial']}
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
          margin={['auto', null, null, 'auto 0']}
        >
          <CTAButton
            actionText="View properties"
            url={propertiesUrl}
            experience={props.experience}
          />
        </Box>
      );
    case ExperienceType.offer_expired:
      return (
        <Box
          minWidth={[null, null, null, '25%']}
          width={[0.8, null, null, 'initial']}
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
          margin={['auto', null, null, 'auto 0']}
        >
          <CTAButton
            loading={loading}
            actionText="Refresh your offer now"
            onClick={fnHandleRefresh(offerDashUrl, props.sellerInputUUID)}
            experience={props.experience}
          />
        </Box>
      );
    case ExperienceType.incomplete_flow:
      // eslint-disable-next-line no-case-declarations
      const sellerFlowUrl = `${CONSUMER_FE_URL}/seller-lead/${props.sellerInputUUID}`;
      return (
        <Box
          minWidth={[null, null, null, '25%']}
          width={[0.8, null, null, 'initial']}
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
          margin={['auto', null, null, 'auto 0']}
        >
          <CTAButton
            actionText="Resume your offer"
            url={sellerFlowUrl}
            experience={props.experience}
          />
        </Box>
      );
    case ExperienceType.offer_expiring:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          minWidth={[null, null, null, '40%']}
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
        >
          <Text
            color="neutrals100"
            fontSize="UNSAFE_h1Mobile"
            fontWeight="bolder"
            px={[null, null, null, 4]}
            pb={[3, null, null, 'initial']}
          >
            $
            {props.currentOfferPriceCents
              ? (props.currentOfferPriceCents / 100).toLocaleString()
              : ''}
          </Text>
          <Box flex="1 0 auto" width={[0.8, null, null, 'initial']} margin="auto">
            <CTAButton actionText="View Offer" url={offerDashUrl} experience={props.experience} />
          </Box>
        </Flex>
      );
    case ExperienceType.offer_decreased:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          minWidth={[null, null, null, '40%']}
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
        >
          <Box
            color="neutrals90"
            fontSize="s00"
            pr={[null, null, null, 7]}
            pb={[4, null, null, 'initial']}
            mx="auto"
          >
            <BenefitLineItem text="Skip showings and repairs" />
            <BenefitLineItem text="Move on your schedule" />
            <BenefitLineItem text="Get paid in a matter of days" />
          </Box>
          <Box flex="1 0 auto" width={[0.8, null, null, 'initial']} margin="auto">
            <CTAButton actionText="View offer" url={offerDashUrl} experience={props.experience} />
          </Box>
        </Flex>
      );
    case ExperienceType.offer_increased:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          justifyContent="space-around"
          minWidth={[null, null, null, '40%']}
          px={[null, null, null, 6]}
          pt={[3, null, null, 7]}
          pb={[5, null, null, 7]}
        >
          <Text
            color="neutrals100"
            fontSize="UNSAFE_h1Mobile"
            fontWeight="bolder"
            px={[null, null, null, 4]}
            pb={[3, null, null, 'initial']}
            borderBottom={[`1px solid ${colors.neutrals40}`, null, null, 'initial']}
            borderRight={[null, null, null, `1px solid ${colors.neutrals40}`]}
          >
            $
            {props.currentOfferPriceCents
              ? (props.currentOfferPriceCents / 100).toLocaleString()
              : ''}
          </Text>
          <Flex px={4} pb={[4, null, null, 'initial']} margin="auto">
            <Text color="neutrals90" fontSize="s000" fontWeight="bolder" px={4} margin="auto">
              Net proceeds
            </Text>
            <Flex px={4}>
              <Image src={imgixUrl('23cae50268d846aaa1e443e0844daf67.svg')} alt="Up arrow" />
              <Text color="green50" fontSize="s0" fontWeight="bolder">
                $
                {(
                  (props.currentOfferPriceCents! - props.previousOfferPriceCents!) /
                  100
                ).toLocaleString()}
              </Text>
            </Flex>
          </Flex>
          <Box flex="1 0 auto" width={[0.8, null, null, 'initial']} margin="auto">
            <CTAButton actionText="View offer" url={offerDashUrl} experience={props.experience} />
          </Box>
        </Flex>
      );
    case ExperienceType.active_offer:
      return (
        <Flex
          flexDirection={['column', null, null, 'initial']}
          minWidth={[null, null, null, '40%']}
          px={[null, null, null, 6]}
          py={[5, null, null, 7]}
        >
          {props.offerRequestSource == 'AUTOMATED_REENGAGEMENT_OFFER' && (
            <Text fontSize="s00" margin="auto">
              We updated your offer
            </Text>
          )}
          <Text
            color="neutrals100"
            fontSize="UNSAFE_h1Mobile"
            fontWeight="bolder"
            px={[null, null, null, 4]}
            pb={[3, null, null, 'initial']}
          >
            $
            {props.currentOfferPriceCents
              ? (props.currentOfferPriceCents / 100).toLocaleString()
              : ''}
          </Text>
          <Box flex="1 0 auto" width={[0.8, null, null, 'initial']} margin="auto">
            <CTAButton actionText="View offer" url={offerDashUrl} experience={props.experience} />
          </Box>
        </Flex>
      );
    default:
      return null;
  }
};

const ReturnExperienceBanner = (props: ReturnExperienceData) => {
  return (
    <Box backgroundColor="neutrals0" paddingBottom={[5, 0]}>
      <Flex
        width={[0.75, null, 0.5, 1]}
        flexDirection={['column', null, null, 'initial']}
        justifyContent={[null, null, null, 'space-between']}
        textAlign={['center', null, null, 'initial']}
        mx="auto"
      >
        <HeadlineComponent
          experience={props.experienceType!}
          firstName={props.firstName}
          address={props.address}
          daysToExpire={props.offerExpiresInDays}
        />
        <OfferDetails
          experience={props.experienceType!}
          sellerInputUUID={props.sellerInputUUID!}
          currentOfferPriceCents={props.currentOfferHeadlinePriceCents}
          previousOfferPriceCents={props.previousOfferHeadlinePriceCents}
          offerRequestSource={props.offerRequestSource}
          customerUUID={props.customerUUID}
          addressToken={props.addressToken}
        />
      </Flex>
    </Box>
  );
};

export default ReturnExperienceBanner;
