/* storybook-check-ignore */
import { useState } from 'react';

import { Box, Button, Icon, Flex } from '@opendoor/bricks/core';
import useQueryParams from '../../helpers/queryParams';

const OutageBanner = () => {
  const { show_outage_banner } = useQueryParams();
  const [showBanner, setShowBanner] = useState(show_outage_banner === 'true');
  const closeBanner = () => setShowBanner(false);

  return (
    <Flex
      display={showBanner ? 'flex' : 'none'}
      py={['16px', '16px', 0]}
      pl="24px"
      // @ts-expect-error custom yellow
      backgroundColor="#fff9e6"
      flexDirection="row"
      justifyContent={[null, null, 'space-between']}
      mx="auto"
    >
      <Box
        maxWidth="80%"
        p={['12px', '12px', '12px', '24px']}
        fontSize={['s1', 's1', 's1', 's2']}
        fontWeight="semibold"
      >
        Offers may be delayed. We’re working through a technical issue that will be resolved
        shortly.
      </Box>
      <Button
        variant="ghost"
        onClick={closeBanner}
        id="outage-banner-close-button"
        aria-label="Close banner"
        analyticsName="cosmos-outage-banner-close"
        p={'24px'}
      >
        <Icon name="close" size={16} aria-hidden="true" />
      </Button>
    </Flex>
  );
};

export default OutageBanner;
