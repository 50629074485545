// storybook-check-ignore
import { css, Global } from '@emotion/react';

const stickyCTAStyles = () => (
  <Global
    styles={css`
      .sticky-cta-bar .od-address-input .cta-input-wrapper {
        border: 1px solid var(--colors-neutrals40);
        border-radius: var(--radii-roundedSquare);
        padding: 5px;
      }
      .sticky-cta-bar .od-address-input button {
        background: var(--colors-brand50);
        color: var(--colors-neutrals0);
        font-weight: 600;
        line-height: normal;
        border: 0;
      }

      .sticky-cta-bar .od-address-input input {
        line-height: normal;
        height: auto;
      }

      .sticky-cta-bar .od-address-input .cta-input-control {
        padding-left: 15px;
      }
      .sticky-cta-bar .od-address-input .result-list {
        list-style: none;
        width: 94% !important;
        margin: 0 3%;
        padding: 0;
        font-size: 14px;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }

      @media (max-width: 767px) {
        .sticky-cta-bar .od-address-input .result-list {
          border-radius: 20px !important;
          overflow: hidden;
          margin-top: 10px;
          box-shadow: 0px 3px 40px rgba(68, 89, 109, 0.2);
        }
      }

      .sticky-cta-bar .od-address-input button {
        padding: 4px 20px;
      }
    `}
  />
);

export default stickyCTAStyles;
