import { Box, Text, Flex, Icon } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import WarningIcon from '@opendoor/bricks/core/Icon/SpritesheetIcons/Warning';

export const isUnsupportedBrowser = () => {
  const ua = typeof window !== 'undefined' && window?.navigator?.userAgent;
  if (!ua) {
    return false;
  }
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  if (isIE) {
    return true;
  }
  return false;
};

const UnsupportedBrowserBanner = () => {
  return (
    <Box backgroundColor="neutrals0" marginTop="55px" padding={5} paddingBottom={0}>
      <SelectiveSpritesheet icons={[WarningIcon]} />
      <Flex alignItems="center">
        <Text fontWeight="bolder" lineHeight={['120', null, '140']}>
          <Icon name="warning" size={16} mr={2} mt="-3px" />
          Opendoor no longer supports this browser. Please upgrade to a supported browser like{' '}
          <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>,{' '}
          <a href="https://www.google.com/chrome/">Chrome</a> or{' '}
          <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>.
        </Text>
      </Flex>
    </Box>
  );
};

export default UnsupportedBrowserBanner;
